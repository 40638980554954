import { observable, action } from 'mobx';

class AlertStore {
  @observable error = null;
  @observable info = null;

  @action clearError = () => {
    this.error = null;
  };

  @action clearInfo = () => {
    this.info = null;
  };

  @action setError = (obj) => {
    this.error = obj;
    setTimeout(this.clearError.bind(this), 5e3);
  };

  @action setInfo = (obj) => {
    this.info = obj;
    setTimeout(this.clearInfo.bind(this), 5e3);
  };
}

export default new AlertStore();
