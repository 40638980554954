import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/cube.png';

const styles = (theme) => ({
  root: {
    // flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    margin: '5px 20px 5px 5px',
    color: 'black',
  },
  logo: {
    margin: '10px 20px 0px 0px',
    height: '48px !important',
  },
  loginButton: {
    margin: '15px auto',
    border: '1px solid #1c3738',
    textTransform: 'none',
    color: '#1c3738',
  },
  menuButton: {
    margin: '15px auto',
    textDecoration: 'none',
  },
  menu: {
    justifyContent: 'left',
    alignItems: 'end',
    display: 'flex',
  },
});

@inject('authStore')
@observer
class Navbar extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar style={{ background: '#fff' }} position="static">
          <Container maxWidth="md">
            <Toolbar>
              <Grid container spacing={3} align-items-xs-flex-start>
                <Grid item xs>
                  <Typography variant="button">
                    <Link to={'/'}>
                      <img src={logo} alt="station" className={classes.logo} />
                    </Link>
                  </Typography>
                </Grid>
                <Hidden only={['sm', 'xs']}>
                  {(this.props.authStore.isAuthenticated && (
                    <Grid item xs={8} className={classes.menu}>
                      <Link to={'/stats'} style={{ color: 'inherit' }}>
                        <Button className={classes.menuButton}>Stats</Button>
                      </Link>
                      <Link to={'/profile'} style={{ color: 'inherit' }}>
                        <Button className={classes.menuButton}>Profile</Button>
                      </Link>
                    </Grid>
                  )) || <Grid item xs={8} className={classes.menu}></Grid>}
                </Hidden>
                <Grid item xs>
                  {!this.props.authStore.isAuthenticated && (
                    <Link to={'/login'} style={{ color: 'inherit' }}>
                      <Button color="black" className={classes.loginButton}>
                        Sign in
                      </Button>
                    </Link>
                  )}
                  {this.props.authStore.isAuthenticated && (
                    <Button color="black" className={classes.loginButton} onClick={this.props.authStore.signOut}>
                      Sign out
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object,
  authStore: PropTypes.object,
};

export default withStyles(styles)(Navbar);
