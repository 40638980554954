import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    marginLeft: 5,
    backgroundColor: '#1c3738',
    color: 'white',
  },
  earlyAdopterText: {
    fontSize: 20,
    fontWeight: 200,
    margin: '20px 0',
  },
});

@inject('authStore', 'alertStore')
@observer
class EmailForm extends Component {
  state = {
    email: '',
  };

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleInputChange({ target }) {
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  checkEmail({ email }) {
    return (
      email === '' ||
      email.search(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) === 0
    );
  }

  async handleButtonClick() {
    if (this.checkEmail(this.state)) {
      await this.props.authStore.earlyAdopters({ email: this.state.email });
    } else {
      this.props.alertStore.setError({ message: 'Please, enter a valid email.' });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.root}>
          <Typography variant="h3" gutterBottom className={classes.earlyAdopterText}>
            Alpha testing has started! Become an early adopter - drop us an email:
          </Typography>
          <div>
            <TextField
              id="outlined-error"
              label="Email Address"
              name="email"
              variant="outlined"
              size="small"
              type="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              error={!this.checkEmail(this.state)}
            />
            <Button className={classes.button} onClick={this.handleButtonClick}>
              Sign up
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

EmailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  authStore: PropTypes.object,
};

export default withStyles(styles)(EmailForm);
