import * as moment from 'moment';

export function generateTimeSeries({ timeframe, step }) {
  let end = moment().startOf('minute');

  if (step.unit === 'minute') {
    end.subtract(end.minutes() % step.value, 'minute');
  }

  let current = moment(end).subtract(timeframe.value, timeframe.unit);

  const result = [];

  for (let i = +current.toDate(); i <= +end.toDate(); i += 6e4) {
    result.push({ x: moment(i).format('HH:mm'), y: 0, ts: i });
  }

  return result;
}
