import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class UpdateProfileDetailsForm extends Component {
  state = {
    firstName: '',
    companyName: '',
    email: '',
    apiKey: '',
  };

  constructor(props) {
    super(props);
    this.profileData = props.profileData;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.profileData !== null) {
      for (const key in this.profileData) {
        this.setState({
          [key]: this.profileData[key],
        });
      }
    }
  }

  handleInputChange({ target }) {
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.root}>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              autoFocus
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="companyName"
              label="Company Name"
              name="companyName"
              autoComplete="companyName"
              autoFocus
              value={this.state.companyName}
              onChange={this.handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.handleInputChange}
            />
            <TextField
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="apiKey"
              label="API key"
              type="apiKey"
              id="apiKey"
              value={this.state.apiKey}
              onChange={this.handleInputChange}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => console.log(e)}
            >
              Update profile
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.object,
  profileData: PropTypes.object,
};

export default withStyles(styles)(UpdateProfileDetailsForm);
/*

*/
