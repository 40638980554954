import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {},
});

@inject('alertStore')
@observer
class Alerts extends Component {
  render() {
    return (
      <Container maxWidth="md">
        {this.props.alertStore.error && (
          <Alert severity="error" onClose={this.props.alertStore.clearError}>
            {this.props.alertStore.error.message}
          </Alert>
        )}
        {this.props.alertStore.info && (
          <Alert severity="info" onClose={this.props.alertStore.clearError}>
            {this.props.alertStore.info.message}
          </Alert>
        )}
      </Container>
    );
  }
}

Alerts.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Alerts);
