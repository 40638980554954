import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import StatList from './components/StatList';

import * as PropTypes from 'prop-types';

@inject('statsStore', 'authStore')
@observer
class StatsListContainer extends Component {
  state = {
    page: 1,
  };

  componentDidMount() {
    this.props.statsStore.fetchStatList();
  }

  render() {
    return !this.props.authStore.isAuthenticated ? (
      <Redirect to={'/login'} />
    ) : (
      <div style={{ minHeight: 'calc(100vh - 194px)' }}>
        <Container maxWidth="md">
          {this.props.statsStore.stats && this.props.statsStore.stats.length > 0 && (
            <StatList stats={this.props.statsStore.stats} />
          )}
        </Container>
      </div>
    );
  }
}

StatsListContainer.propTypes = {
  statsStore: PropTypes.object,
  authStore: PropTypes.object,
};

export default StatsListContainer;
