import { observable, action } from 'mobx';
import API, { setAuthenticationToken } from '../api/api';
import { API_ROUTES } from '../api/routes';
import alertStore from './alertStore';

class AuthStore {
  @observable inProgress = false;
  @observable errors = undefined;
  @observable isAuthenticated = localStorage.getItem('authToken') !== null;
  @observable profile = null;

  @observable token = localStorage.getItem('authToken');

  @action loadProfile = async () => {
    this.inProgress = true;
    this.errors = undefined;

    try {
      const { data } = await API.get(API_ROUTES.PROFILE);
      this.profile = { ...data };
    } catch (e) {
      return { error: 'Invalid email or password!' };
    } finally {
      this.inProgress = false;
    }
  };

  @action earlyAdopters = async ({ email }) => {
    this.inProgress = true;

    try {
      const result = await API.post(API_ROUTES.EARLY_ADOPTERS, { email });
      alertStore.setInfo({ message: 'Thanks! We will contact you soon :)' });
    } catch (e) {
      alertStore.setError({ message: 'Invalid email!' });
    } finally {
      this.inProgress = false;
    }
  };

  @action login = async ({ email, password }) => {
    this.inProgress = true;
    this.errors = undefined;

    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.LOGIN, { email, password });
      setAuthenticationToken(token);

      this.isAuthenticated = true;
    } catch (e) {
      return { error: 'Invalid email or password!' };
    } finally {
      this.inProgress = false;
    }
  };

  @action signOut = async () => {
    this.inProgress = true;
    this.errors = undefined;

    try {
      this.isAuthenticated = false;
      localStorage.removeItem('authToken');
    } catch (e) {
      return { error: 'Invalid email or password!' };
    } finally {
      this.inProgress = false;
    }
  };

  @action register = async ({ firstName, companyName, email, password, passwordConfirmation }) => {
    this.inProgress = true;
    this.errors = undefined;

    try {
      const {
        data: { error },
      } = await API.post(API_ROUTES.REGISTER, { firstName, companyName, email, password, passwordConfirmation });

      if (error) {
        this.errors = [error];
      }
    } catch (e) {
      return { error: 'Something went wrong!' };
    } finally {
      this.inProgress = false;
    }
  };
}

export default new AuthStore();
