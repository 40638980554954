import { action, observable, computed } from 'mobx';
import API from '../api/api';
import { API_ROUTES } from '../api/routes';
import { generateTimeSeries } from '../utils/timeSeriesTools';
import { minBy, maxBy, sumBy, meanBy } from 'lodash';

export class StatsStore {
  @observable stats = null;
  @observable statData = null;
  @observable selectedStatName = '';
  @observable isLoading = false;
  @observable error = null;
  @observable timeframe = {
    unit: 'hour',
    value: 1,
  };
  @observable step = {
    unit: 'minute',
    value: 1,
  };

  @computed get min() {
    return minBy(this.statData, 'y').y;
  }

  @computed get max() {
    return maxBy(this.statData, 'y').y;
  }

  @computed get total() {
    return sumBy(this.statData, 'y');
  }

  @computed get mean() {
    return meanBy(this.statData, 'y');
  }

  @action fetchStatList = async () => {
    this.stats = [];

    try {
      const {
        data: { data },
      } = await API.get(API_ROUTES.LIST_STATS);
      this.stats = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchStatData = async (statId) => {
    this.statData = null;
    this.isLoading = true;

    try {
      this.statData = generateTimeSeries({ timeframe: { unit: 'hour', value: 1 }, step: { unit: 'minute', value: 1 } });
      const {
        data: { data, name },
      } = await API.get(API_ROUTES.STAT_DATA(statId, this.statData[0].ts));
      this.selectedStatName = name;

      const tsObj = {};

      for (const item of data) {
        tsObj[+new Date(item.fromDate)] = item.value;
      }

      this.statData = this.statData.map((e) => {
        if (tsObj[e.ts]) {
          e.y = tsObj[e.ts];
        }
        return e;
      });
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new StatsStore();
