import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LoginForm from './components/LoginForm';

@inject('authStore')
@observer
class LoginFormContainer extends Component {
  render() {
    return (
      <Container maxWidth="md">
        {this.props.authStore.isAuthenticated ? (
          <Redirect to={'/stats'} />
        ) : (
          <LoginForm login={this.props.authStore.login} />
        )}
      </Container>
    );
  }
}

export default LoginFormContainer;
