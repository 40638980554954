import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

const AUTH_TOKEN = localStorage.getItem('authToken');

if (AUTH_TOKEN) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response.data);
  },
);

export const setAuthenticationToken = (token) => {
  localStorage.setItem('authToken', token);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default axiosInstance;
