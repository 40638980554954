import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  root: {
    boxShadow: 'none',
  },
});

@inject('statsStore')
@observer
class SummaryTable extends Component {
  render() {
    const { classes, data, statsStore } = this.props;

    return (
      <TableContainer component={Paper} className={classes.root}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">min</TableCell>
              <TableCell align="right">max</TableCell>
              <TableCell align="right">mean</TableCell>
              <TableCell align="right">total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Timeframe</TableCell>
              <TableCell align="right">{statsStore.min}</TableCell>
              <TableCell align="right">{statsStore.max}</TableCell>
              <TableCell align="right">{statsStore.mean}</TableCell>
              <TableCell align="right">{statsStore.total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

SummaryTable.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  statsStore: PropTypes.object,
};

export default withStyles(styles)(SummaryTable);
