import React, { Component } from 'react';
import EmailForm from './components/EmailForm';
import Container from '@material-ui/core/Container';

class Homepage extends Component {
  render() {
    return (
      <Container maxWidth="md">
        <EmailForm />
      </Container>
    );
  }
}

export default Homepage;
