import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    padding: 20,
    backgroundColor: '#000f08',
    color: 'aliceblue',
    fontSize: 14,
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    const date = new Date();

    return (
      <footer className={classes.root}>
        <Container maxWidth="md">
          <div>&copy; {date.getFullYear()} Station Ltd.</div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Footer);
