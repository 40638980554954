import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import UpdateProfileDetailsForm from './components/UpdateProfileDetailsForm';

@inject('authStore')
@observer
class RegisterFormContainer extends Component {
  componentDidMount() {
    this.props.authStore.loadProfile();
  }

  render() {
    return (
      <Container maxWidth="md">
        {this.props.authStore.profile && <UpdateProfileDetailsForm profileData={this.props.authStore.profile} />}
      </Container>
    );
  }
}

export default RegisterFormContainer;
