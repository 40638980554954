import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as stores from './stores';
import { Provider } from 'mobx-react';
import Navbar from './components/Navbar';
import Alerts from './components/Alerts';
import Footer from './components/Footer';
import StatsList from './views/StatsList';
import Stat from './views/Stat';
import LoginForm from './views/LoginForm';
import RegisterForm from './views/RegisterForm';
import Profile from './views/Profile';
import Homepage from './views/Homepage';
import './App.css';
import 'typeface-roboto';

const hist = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Router history={hist}>
          <div className="App">
            <Navbar />
            <Alerts />
            <Switch>
              <Route path="/stats" component={StatsList} />
              <Route path="/stat/:id" component={Stat} />
              <Route path="/login" component={LoginForm} />
              <Route path="/register" component={RegisterForm} />
              <Route path="/profile" component={Profile} />
              <Route path="/" component={Homepage} />
              <Route path="*" render={() => <Redirect to={'/login'} />} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </Provider>
    );
  }
}

export default App;
