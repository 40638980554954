import React, { Component } from 'react';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Line } from 'recharts';
import PropTypes from 'prop-types';
import SummaryTable from './SummaryTable';
import Container from '@material-ui/core/Container';

class Chart extends Component {
  render() {
    const { data, statName } = this.props;

    return (
      <Container maxWidth="md">
        <h2>{statName}</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
            <XAxis dataKey="x" interval="preserveStartEnd" minTickGap={50} />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="y" stroke="#8884d8" type="step" />
          </LineChart>
        </ResponsiveContainer>
        <SummaryTable />
      </Container>
    );
  }
}

Chart.propTypes = {
  data: PropTypes.object,
};

export default Chart;
