import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import RegisterForm from './components/RegisterForm';

@inject('authStore')
@observer
class RegisterFormContainer extends Component {
  render() {
    return (
      <Container maxWidth="md">
        <RegisterForm register={this.props.authStore.register} />
      </Container>
    );
  }
}

export default RegisterFormContainer;
