import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Chart from './components/Chart';

@inject('statsStore', 'authStore')
@observer
class StatContainer extends Component {
  state = {
    page: 1,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.props.statsStore.fetchStatData(params.id);
  }

  render() {
    return !this.props.authStore.isAuthenticated ? (
      <Redirect to={'/login'} />
    ) : (
      <div style={{ minHeight: 'calc(100vh - 194px)' }}>
        {this.props.statsStore.statData !== null ? (
          <Chart data={this.props.statsStore.statData} statName={this.props.statsStore.selectedStatName} />
        ) : (
          'Loading'
        )}
      </div>
    );
  }
}

StatContainer.propTypes = {
  statsStore: PropTypes.object,
  authStore: PropTypes.object,
};

export default StatContainer;
